
import SEO from '~/mixins/SEO.js'
import BielbitBlogPosts from '~/mixins/BielbitBlogPosts.js'

export default {
  name: 'IndexPage',
  mixins: [SEO, BielbitBlogPosts],
  data() {
    return {
      modal: {
        id: 'popupModal',
      },
      heroUnitImg: '/grafika_tytulowa@2x.png',
      rawPageTitle: 'IT po partnersku',
      rawPageDescription:
        'Stawiamy na długotrwałą współpracę opartą na rozwiązaniach indywidualnie dobranych do Twoich potrzeb. Szukasz oprogramowania dla firmy którą prowadzisz? Wspólnie znajdziemy drogę do rozwoju Twojego przedsiębiorstwa. ',
      keywords: [
        'firma',
        'partner',
        'część',
        'oprogramowanie',
        'bielbit',
        'rozwiązanie',
        'potrzeba',
        'komputerowy',
        'problem',
        'sprzedaż',
        'księgowość',
        'system',
        'bielski',
        'insert',
        'serwis',
        'biuro',
        'rachunkowy',
      ],
    }
  },
  jsonld() {
    return {
      '@context': 'http://schema.org/',
      '@type': [
        'LocalBusiness',
        'AccountingService',
        'ProfessionalService',
        'ComputerStore',
      ],
      name: 'BIELbit sp. z o.o.',
      legalName: 'BIELbit sp. z o.o.',
      slogan: 'IT po partnersku',
      logo: 'https://www.bielbit.pl/icon.png',
      image: 'https://www.bielbit.pl/icon.png',
      telephone: '+48 33 472 33 33 ',
      url: 'https://www.bielbit.pl/',
      email: 'bielbit@bielbit.pl',
      address: {
        '@type': 'PostalAddress',
        streetAddress: 'ul. Komorowicka 45',
        addressLocality: 'Bielsko-Biała',
        addressRegion: 'śląskie',
        postalCode: '43-300',
        addressCountry: 'Poland',
      },
      geo: {
        '@type': 'GeoCoordinates',
        latitude: 49.8324264,
        longitude: 19.0512995,
      },
      hasMap:
        'https://www.google.com/maps/place/Komorowicka+45,+43-300+Bielsko-Bia%C5%82a/@49.8324264,19.0512995,17z/data=!3m1!4b1!4m5!3m4!1s0x4716a1e21db10573:0xc0f314c8b6fc3202!8m2!3d49.8324264!4d19.0534882',
      openingHoursSpecification: [
        {
          '@type': 'OpeningHoursSpecification',
          dayOfWeek: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
          opens: '08:00',
          closes: '16:00',
        },
      ],
      sameAs: ['https://pl.linkedin.com/company/bielbit'],
    }
  },
  computed: {
    preparedDataForNewsModal() {
      const newsModalId = 'newsModal'
      const countOfPosts = this.posts.length ? 1 : 0
      let modalId = newsModalId
      const newPostList = []
      for (let index = 0; index < countOfPosts; index++) {
        const post = this.posts[index]
        post.title.rendered = this.stripHtmlTagsFromText(post.title.rendered)
        modalId = newsModalId + '-' + post.id + '-' + post.slug
        newPostList.push(post)
      }
      console.log(modalId)
      return {
        id: modalId,
        posts: newPostList,
      }
    },
  },
  mounted() {
    console.log(new Date())
    if (!this.$cookies.get('is-' + this.preparedDataForNewsModal.id))
      setTimeout(() => {
        console.log(new Date())
        // Kod, który ma zostać wykonany po 10 sekundach
        console.log('Otwieram modal z newsem po 2 sekundach')
        this.$bvModal.show(this.preparedDataForNewsModal.id)
      }, 2000) // 2000 milisekund = 2 sekund
    else console.log(this.preparedDataForNewsModal.id + ' is hidden by cookie')
    //  if (!this.$cookies.get('isPopupShowedTemp3'))
    //    this.$bvModal.show(this.modal.id)
  },
}
